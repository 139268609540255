<template>
  <b-container>
     <b-row class="justify-content-md-center mt-5">
        <b-col md="5">
          <b-form-group id="input-group-1" label="Email" label-for="input-1" description="">
            <b-form-input id="email" v-model="mtbemail" type="email" required placeholder="Your MTB email" @keydown.enter="loginGoogle()"></b-form-input>
          </b-form-group>

          <button class="btn mt-4 btn-primary" @click="loginGoogle()">Sign In <b-spinner v-if="loginStarted" small /></button>

          <p v-if="feedback" class="text-danger mt-3">{{ feedback }}</p>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-5">
        <b-col>
          <a class="text-small " v-if="$route.path == '/login-mtb'" href="/login">If you are not a MTB employee, please go to this page.</a>
        </b-col>
      </b-row>

  </b-container>
</template>

<script>
import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/analytics");

import axios from "axios"

export default {
  name: "Login",
  data() {
    return {
      mtbemail: null,
      feedback: null,
      form: {
        email: "",
        password: ""
      },
      emailForgottenPassword: null,
      loginStarted: false
    };
  },
  created() {
    if (firebase.auth().currentUser) {
      this.$router.push({ path: "/reports" });
    }
  },
  methods: {
    loginGoogle() {
      this.loginStarted = true;
      if (!this.mtbemail) {
        this.feedback = "Please fill in the email field with your MTB email";

        this.loginStarted = false;
        return;
      }

      if (!this.mtbemail.endsWith("@mindthebridge.org")) {
        this.feedback = "Please use your .org MTB email address";

        this.loginStarted = false;
        return;
      }


      var provider = new firebase.auth.GoogleAuthProvider().setCustomParameters({ login_hint: this.mtbemail });

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result=>{
          let user = result.user


      axios
        .get("https://us-central1-mtb-research.cloudfunctions.net/assignClaimMtb", {
          params: {
            uid: result.user.uid,
          },
        })
        .then(() => {
          this.$store.dispatch("setUserInfo")
        })
        .catch((err) => {
          console.log(err);
        });

        this.loginStarted = false;

        })
        .catch((error) => {
          console.log(error);
          this.feedback = error;
        });

    },
    emailVerifiedToast() {
      this.toastCount++;
      this.$bvToast.toast("Your email has been successfully verified. You can now log in!", {
        title: "Email Verified",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: false,
      });
    },
    passwordResetToast() {
      this.toastCount++;
      this.$bvToast.toast("We sent you an email to reset your password", {
        title: "Password Reset",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: false,
      });
    },
    forgottenPassword(emailAddress) { 
      let auth = firebase.auth();
      var actionCodeSettings = {
        url: "https://research.mindthebridge.com/login",
      };
      auth.sendPasswordResetEmail(emailAddress,actionCodeSettings).then(() => {
        this.passwordResetToast();
      });
    },
    login() {
      if (this.form.email && this.form.password) {
        this.feedback = null;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then((userCredential) => {

            firebase.analytics().logEvent('login').then(()=>{console.log('login event logged')})

            firebase
              .firestore()
              .collection("users")
              .doc(userCredential.user.email)
              .get()
              .then((doc) => {
                var userInfo = doc.data();
                this.$store.commit("SET_USER_INFO", userInfo);
                if (!userInfo.hasCompletedSignup) {
                  this.$router.push({ name: "CompleteSignup" });
                } else {
                  this.$router.push({ name: "Reports" });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.feedback = err.code;
            switch (err.code) {
              case "auth/wrong-password":
                this.feedback = "The provided password is wrong.";
                break;

              case "auth/user-not-found":
                this.feedback = "The provided email is not associated to any user.";
                break;

              case "auth/too-many-requests":
                this.feedback = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
                break;

              default:
                this.feedback = null;
                break;
            }
          });
      } else {
        this.feedback = "Please fill in both fields";
      }
    },
  },
};
</script>
